import { createRouter, createWebHistory } from 'vue-router'
import { hasAuthentication, setAuthentication } from './api-config'
import { getDuration, timeNow } from './shared/fns'
import { useTitle } from './shared/use-title'
import { module } from './module/module-state'
import { hasModule, hasRole } from './user/user-logic'
import { submitOpsEvent } from './ops-log/ops-log-api'
import { genericOpsEventBase } from './ops-log/ops-log-logic'
import { systemLoading } from './components/system-loading-state'
import { hashOrIndex } from './docs/md-loader'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: () => {
      if (module.value === 'signaling') {
        return { name: 'Signaling' }
      }
      if (module.value === 'training') {
        if (hasRole('engineer')) {
          return { name: 'Training' }
        }
        if (hasRole('instructor')) {
          return { name: 'Courses' }
        }
        if (hasRole('student')) {
          return { name: 'MyLessons' }
        }
      }
      if (module.value === 'energy') {
        return { name: 'Energy' }
      }
      if (hasRole('engineer')) {
        if (hasModule('signaling')) {
          return { name: 'Signaling' }
        }
        if (hasModule('training')) {
          return { name: 'Training' }
        }
        if (hasModule('energy')) {
          return { name: 'Energy' }
        }
        return { name: 'Docs' }
      }
      if (hasRole('instructor')) {
        return { name: 'Courses' }
      }
      if (hasRole('student')) {
        return { name: 'MyLessons' }
      }
      if (hasRole('root')) {
        return { name: 'Companies' }
      }
      if (hasRole('admin')) {
        return { name: 'Users' }
      }
      return { name: 'Login' }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('./user/LoginScreen'),
    meta: { title: 'Login' }
  },
  {
    path: '/request-password-reset',
    name: 'RequestPasswordReset',
    component: () => import('./user/RequestPasswordResetScreen'),
    meta: { title: 'Request password reset' }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('./user/ResetPasswordScreen'),
    props: ({ query: { token } }) => ({ token }),
    meta: { title: 'Reset password' }
  },
  {
    path: '/user-settings',
    name: 'UserSettings',
    component: () => import('./user/UserSettingsScreen'),
    meta: { title: 'User settings' }
  },
  {
    path: '/layouts/:layoutId/layout-versions/:layoutVersionId/simulations/:simulationId/run-report',
    name: 'SimulationRunReport',
    component: () => import('./simulation-run-report/SimRunReportScreen'),
    props: ({ params: { layoutId, layoutVersionId, simulationId } }) => ({
      layoutId: Number(layoutId),
      layoutVersionId: Number(layoutVersionId),
      simulationId: Number(simulationId)
    }),
    meta: { title: 'Simulation run report' }
  },
  {
    path: '/layouts/:layoutId/layout-versions/:layoutVersionId/simulations/:simulationId/static-report',
    name: 'SimulationStaticReport',
    component: () => import('./simulation-static-report/SimStaticReportScreen'),
    props: ({ params: { layoutId, layoutVersionId, simulationId } }) => ({
      layoutId: Number(layoutId),
      layoutVersionId: Number(layoutVersionId),
      simulationId: Number(simulationId)
    }),
    meta: { title: 'Simulation static report' }
  },
  {
    path: '/layouts/:layoutId',
    name: 'Layout',
    component: () => import('./layout/LayoutEditorScreen'),
    props: ({
      params: { layoutId },
      query: { mode, simulation, layoutVersionId }
    }) => ({
      layoutId: Number(layoutId),
      initialMode: mode,
      initialSimulation: simulation && Number(simulation),
      layoutVersionId: layoutVersionId && Number(layoutVersionId)
    })
  },
  {
    path: '/layout-snapshots/:snapshotKey',
    name: 'LayoutSnapshot',
    component: () => import('./layout-snapshot/LayoutSnapshotScreen'),
    props: ({ params: { snapshotKey } }) => ({ snapshotKey })
  },
  {
    path: '/signaling',
    name: 'Signaling',
    component: () => import('./project/ProjectListScreen'),
    props: { module: 'signaling' },
    meta: { title: 'Signaling' }
  },
  {
    path: '/training',
    name: 'Training',
    component: () => import('./project/ProjectListScreen'),
    props: { module: 'training' },
    meta: { title: 'Training' }
  },
  {
    path: '/energy',
    name: 'Energy',
    component: () => import('./project/ProjectListScreen'),
    props: { module: 'energy' },
    meta: { title: 'Energy' }
  },
  {
    path: '/projects/:projectId',
    name: 'Project',
    component: () => import('./project/ProjectDetailScreen'),
    props: ({ params: { projectId } }) => ({
      projectId: Number(projectId)
    })
  },
  {
    path: '/scenes/:sceneId/edit',
    name: 'EditScene',
    component: () => import('./scene/edit/SceneEditScreen'),
    props: ({ params: { sceneId } }) => ({
      sceneId: Number(sceneId)
    }),
    meta: {
      title: 'Edit scene',
      module: 'training'
    }
  },
  {
    path: '/scenes/:sceneId/run/:mode',
    name: 'RunScene',
    component: () => import('./scene/run/SceneRunScreen'),
    props: ({ params: { sceneId, mode } }) => ({
      sceneId: Number(sceneId),
      mode
    })
  },
  {
    path: '/lessons/:lessonId/edit',
    name: 'EditLesson',
    component: () => import('./lesson/edit/LessonEditScreen'),
    props: ({ params: { lessonId } }) => ({
      lessonId: Number(lessonId)
    }),
    meta: {
      title: 'Edit lesson',
      module: 'training'
    }
  },
  {
    path: '/lessons/:lessonId/run/:mode',
    name: 'RunLesson',
    component: () => import('./lesson/run/LessonRunScreen'),
    props: ({
      params: { lessonId, mode },
      query: { lessonRunId }
    }) => ({
      lessonId: Number(lessonId),
      mode,
      lessonRunId: lessonRunId ? Number(lessonRunId) : null
    })
  },
  {
    path: '/my-lessons',
    name: 'MyLessons',
    component: () => import('./my-lesson-run/list/MyLessonsScreen'),
    meta: {
      title: 'My lessons',
      module: 'training'
    }
  },
  {
    path: '/courses/new',
    name: 'CreateCourse',
    component: () => import('./course/edit/CourseEditScreen'),
    meta: {
      title: 'Create course',
      module: 'training'
    }
  },
  {
    path: '/courses/:courseId/edit',
    name: 'EditCourse',
    component: () => import('./course/edit/CourseEditScreen'),
    props: ({ params: { courseId } }) => ({
      courseId: Number(courseId)
    }),
    meta: { module: 'training' }
  },
  {
    path: '/courses/:courseId',
    name: 'CourseDetail',
    component: () => import('./course/detail/CourseDetailScreen'),
    props: ({ params: { courseId } }) => ({
      courseId: Number(courseId)
    }),
    meta: { module: 'training' }
  },
  {
    path: '/courses',
    name: 'Courses',
    component: () => import('./course/list/CourseListScreen'),
    props: ({ query: { search, instructorId, status, archived } }) => ({
      search,
      instructorId: instructorId ? Number(instructorId) : null,
      status,
      archived: archived === 'true' || null
    }),
    meta: {
      title: 'Courses',
      module: 'training'
    }
  },
  {
    path: '/docs',
    name: 'Docs',
    component: () => import('./docs/DocsScreen'),
    props: ({ hash }) => ({
      hash: hash ? hash.substring(1) : hashOrIndex()
    }),
    meta: { title: 'Documentation' }
  },
  {
    path: '/companies/new',
    name: 'CreateCompany',
    component: () => import('./company/CompanyEditScreen'),
    meta: { title: 'Create company' }
  },
  {
    path: '/companies/:companyId',
    name: 'EditCompany',
    component: () => import('./company/CompanyEditScreen'),
    props: ({ params: { companyId } }) => ({
      companyId: Number(companyId)
    }),
    meta: { title: 'Edit company' }
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () => import('./company/CompanyListScreen'),
    props: ({ query: { search, archived } }) => ({
      search,
      archived: archived === 'true' || null
    }),
    meta: { title: 'Companies' }
  },
  {
    path: '/users/new',
    name: 'CreateUser',
    component: () => import('./user/UserEditScreen'),
    meta: { title: 'Create user' }
  },
  {
    path: '/users/:userId',
    name: 'EditUser',
    component: () => import('./user/UserEditScreen'),
    props: ({ params: { userId } }) => ({
      userId: Number(userId)
    }),
    meta: { title: 'Edit user' }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('./user/UserListScreen'),
    props: ({ query: { search, archived, companyId } }) => ({
      search,
      archived: archived === 'true' || null,
      companyId: companyId ? Number(companyId) : null
    }),
    meta: { title: 'Users' }
  },
  {
    path: '/audit-logs',
    name: 'AuditLogs',
    component: () => import('./audit-log/AuditLogListScreen'),
    props: ({ query: { entityName, entityAction, entityId, userId, createTimeTo } }) => ({
      entityName,
      entityAction,
      entityId,
      userId: userId ? Number(userId) : null,
      createTimeTo
    }),
    meta: { title: 'Audit log' }
  },
  {
    path: '/ops-logs',
    name: 'OpsLogs',
    component: () => import('./ops-log/OpsLogListScreen'),
    props: ({
      query: {
        severity, activity, identifier, name, application, deviceId, ipAddress, country, city, userId,
        pageSessionId, createTimeTo
      }
    }) => ({
      severity,
      activity,
      identifier,
      name,
      application,
      deviceId,
      ipAddress,
      country,
      city,
      userId: userId ? Number(userId) : null,
      pageSessionId,
      createTimeTo
    }),
    meta: { title: 'Ops log' }
  },
  {
    path: '/usage-report',
    name: 'UsageReport',
    component: () => import('./report/usage/UsageReportScreen'),
    props: ({ query: { dateFrom, dateTo } }) => ({ dateFrom, dateTo }),
    meta: { title: 'Usage report' }
  },
  { // support saved URLs from time when this used to be Next FE, especially snapshots
    path: '/next/:pathMatch(.*)*',
    name: 'SavedNextURL',
    redirect: to => ({ path: to.path.replace('/next', '') })
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: 'Home' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active'
})

const { setTitle } = useTitle()

let before = null
let first = true

router.beforeEach((to, from, next) => {
  before = timeNow()
  const title = to.meta?.title
  if (title) {
    setTitle(title)
  }
  const moduleMeta = to.meta?.module
  if (moduleMeta) {
    module.value = moduleMeta
  }
  if (!hasAuthentication()) { // Navigating after has logged-in in other tab
    setAuthentication()
  }
  next()
  systemLoading.value = false
})

const navigationOpsLogThreshold = 0 // 50?

const recordNavigationDuration = () => {
  const duration = getDuration(before)
  if (duration >= navigationOpsLogThreshold) {
    const data = { duration }
    if (first) {
      data.first = first
      first = false
    }
    const event = {
      ...genericOpsEventBase(),
      severity: duration > 1_000 ? 'warning' : 'info',
      name: 'navigation',
      data
    }
    submitOpsEvent(event)
  }
}

router.afterEach(recordNavigationDuration)

export default router
